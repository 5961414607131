.section-3{
  background: linear-gradient(#142C5B, #1A4EAF);
  /* height: 300px; */
  width: 100%;
  display: flex;
}

.section-3-container{
  padding: 40px;
  width: 95%;
  display: inherit;
  flex-direction: column;
  align-items: center
}

.section-3-container h1{
  font-size: 100px;
  text-shadow:
    0 1px #0a5677,
    1px 0 #68a8cf,
    1px 2px #0a5677,
    2px 1px #68a8cf,
    2px 3px #0a5677,
    3px 2px #68a8cf,
    3px 4px #0a5677,
    4px 3px #68a8cf,
    4px 5px #0a5677,
    5px 4px #68a8cf,
    5px 6px #0a5677,
    6px 5px #68a8cf
}

@media screen and (max-width: 1500px) {
  .section-3-container h1{
    text-align: center;
    font-size: 90px;
  }
}

@media screen and (max-width: 900px) {
  .section-3-container h1{
    font-size: 70px;
  }
}

@media  screen and (max-width:500px) {
  .section-3-container h1{
    font-size: 50px;
    text-align: center !important;
  }
}
/* .rare-container{
 max-width:80%;
 height: 400px;
 display:grid;
 grid-template-columns:repeat(auto-fill,minmax(300px,1fr));
 grid-auto-flow: column;
 grid-gap: 16px;
 overflow-x: scroll;
 overflow-y: hidden;
 padding: 60px;
 border-radius: 5px;
} */

.rare-container {
  display: flex;
  flex-wrap: wrap;
  padding: 60px;
  width: 100%;
  justify-content: center
}

.rare-items{

  display: inline-block;
  width: 300px;
  height: 400px;
  background-color: #eec0c6;
  background-image: linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%);
  box-shadow: 10px 10px 5px rgb(11, 36, 83);

  /* margin: 10px; */
  border-radius: 47%;
  display: inherit;
  align-items: center;
  margin: 40px 15px;

}

.rare-items img{
  width: 300px;
  height: 300px;
}

/* .rare-container {
  -webkit-overflow-scrolling: touch;
} */

.rare-container::-webkit-scrollbar {
 height: 10px;

}


.rare-container::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: transparent;
}

.rare-container::-webkit-scrollbar-thumb {
background-color: rgb(180, 82, 155);
border-radius: 5px;
/* outline: 1px solid slategrey !important; */
/* background-image: url('../Section1/images/pinkstar.png'); */
/* cursor: pointer; */
background-size: cover;

}
