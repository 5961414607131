.section-2{
  background: linear-gradient(#0D1C39, #142C5B);
  /* height: 300px; */
  width: 100%;
  display: flex;
  /* padding-top: 150px; */
}

.section-2-container{
  padding: 40px;
  width: 100%;
  display: inherit;
  flex-direction: column;
  align-items: center
}

.section-2-container h1{
  font-size: 100px;
  text-shadow:
    0 1px #0a5677,
    1px 0 #68a8cf,
    1px 2px #0a5677,
    2px 1px #68a8cf,
    2px 3px #0a5677,
    3px 2px #68a8cf,
    3px 4px #0a5677,
    4px 3px #68a8cf,
    4px 5px #0a5677,
    5px 4px #68a8cf,
    5px 6px #0a5677,
    6px 5px #68a8cf
}

@media screen and (max-width: 1500px) {
  .section-2-container h1{
    text-align: center;
    font-size: 90px;
  }
}

@media screen and (max-width: 1250px) {
  .whymsg p{

    font-size: 32px !important;
  }
}

@media screen and (max-width: 1000px) {
  .whymsg p{

    font-size: 30px !important;
  }
}

@media screen and (max-width: 900px) {

  .section-2-container h1{
    font-size: 70px;
  }
  .whymsg{
    flex-direction: column;
    align-items: center
  }
  .whymsg p {
    width: 95% !important;
  }

  .whymsg img{
    width: 90% !important
  }
}

@media  screen and (max-width:500px) {
  .section-2-container h1{
    font-size: 50px;
  }
  .whymsg p{

    font-size: 25px !important;
  }

  .whymsg img{
    height: 300px !important;
  }
}
.whymsg p{
  font-size: 35px;
  /* text-align:unset; */
  width:45%;
  padding: 20px;

  /* text-shadow:
    0 1px #0a5677,
    1px 0 #68a8cf,
    1px 2px #0a5677,
    2px 1px #68a8cf,
    2px 3px #0a5677 */

    text-shadow: -1px -1px 1px rgba(10, 10, 10, 0.1), 1px 1px 1px rgba(0,0,0,.5);

}
.whymsg{
  justify-content: space-around;
  display: flex;
}


.whymsg img{
  width: 45%;
  height: 500px;
  background-color: rgb(26, 52, 156);
  opacity: 0.3
}
