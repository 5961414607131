.section-6{
  background: linear-gradient(#6889C8,#ADC3ED ) ;
  /* height: 300px; */
  width: 100%;
  display: flex;
}

.section-6-container{
  padding: 40px;
  width: 100%;
  display: inherit;
  flex-direction: column;
  align-items: center
}
/*

.roadmap-container{
  width: 100%;
} */

/* .element-container-1{
  display: flex;
  justify-content: flex-start;
  padding: 60px
} */

/* .element-container-2{
  display: flex;
  justify-content: flex-end;
  padding: 60px
} */

/* .circle-element {
  width:500px;
  height: 500px;
  background-color: white;
  border-radius: 50%;
} */


