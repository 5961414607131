.menu-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.menu-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0;
  margin-top: 10px;
  border-radius: 10px;
  opacity: 0.7;
  padding:10px
}

.menu {
  position: relative;
  display: inline-block;
}

.menubtn {
  background-color: #04AA6D;
  color: white;
  /* padding: 16px; */
  /* font-size: 16px; */
  border: none;
}

@media screen and (max-width: 1100px) {
  .menu img{
    height: 56px !important;
    width: 59px !important;
    cursor: pointer;
  }
}

@media screen and (max-width: 900px) {
  .menu img{
    height: 46px !important;
    width: 49px !important;
    cursor: pointer;
  }
 
}

.menu-content a:hover {background-color: #ddd;}

.menu:hover .menu-content {display: block;}

/* .menu:hover .menubtn {background-color: #3e8e41;} */

.menu img{
  height: 65px;
  width: 68px;
  cursor: pointer;
}


