.section-1{
  /* background-color: #070F1E; */
  background: linear-gradient(#070F1E, #0D1C39);
  /* height: 600px; */
  width: 100%;
  height: 1200px;
  overflow-x: hidden;
}

.section-1-container{
 padding: 40px;
 /* height: 100%; */
}

.nav {
  display: flex;
  justify-content: space-between
}

/* .nav img{
  height: 40px;
  width: 45px;
} */

.nav button {
  background-color: transparent;
  border: none;
  margin-left: 20px;
}

/* .discordImg{
  height: 45px;
  width: 80px;
}

.instaImg{
  height: 48px;
  width: 48px;
}

.twitterImg{
  height: 46px;
  width: 53px;
}

.openSeaImg{
  height: 45px;
  width: 52px;

} */

.discordImg{
  height: 65px;
  width: 100px;
}

.instaImg{
  height: 68px;
  width: 68px;
}

.twitterImg{
  height: 60px;
  width: 68px;
}

.openSeaImg{
  height: 65px;
  width: 65px;

}

.nav {
  /* position: fixed; */

}



.nav a {
  text-decoration: none;
  color: white;
  font-size: 40px;
}

.msg1-container{
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  /* height: 100vh; */
}


.msg1{
  font-size: 45px;
  max-width: 70%;
  text-align: center;

  /* font-family: 'finger paint';
  letter-spacing: 3px; */

  /* font-family: 'abys'; */
  /* letter-spacing: 8px; */

  font-family: 'genty sans';
  letter-spacing: 3px;
}

.msg1 h1{
  /* background-color: #fff; */
  /* color: #202c2d; */
  text-shadow:
    0 1px #0a5677,
    1px 0 #68a8cf,
    1px 2px #0a5677,
    2px 1px #68a8cf,
    2px 3px #0a5677,
    3px 2px #68a8cf,
    3px 4px #0a5677
    /* 4px 3px #68a8cf,
    4px 5px #0a5677,
    5px 4px #68a8cf,
    5px 6px #0a5677,
    6px 5px #68a8cf */

}
@media  screen and (max-width: 1400px) {
  .nif.one, .nif.three{
    display: none;
  }

  .niftFloat-container {
    margin-top: 30px !important;
  }

  .niftFloat-container > div {
    height: 400px !important;
    width: 400px !important;
    background: transparent;
    /* animation: rotation1 10s infinite linear; */
  }
}

@media  screen and (max-width: 1100px) {
  .section-1 {
    height: 1500px !important
  }

  .discordImg{
    height: 55px;
    width: 90px;
  }

  .instaImg{
    height: 55px;
    width: 55px;
  }

  .twitterImg{
    height: 52px;
    width: 58px;
  }

  .openSeaImg{
    height: 55px;
    width: 55px;

  }

  .nav button {
    margin-left: 5px;
  }

  .nifty-logo span{
    font-size: 35px
  }
}


@media  screen and (max-width:900px) {
  .section-1 {
    height: 1500px !important
  }

  .msg1 {
    font-size: 38px;
  }

  .discordImg{
    height: 45px;
    width: 65px;
  }

  .instaImg{
    height: 45px;
    width: 45px;
  }

  .twitterImg{
    height: 42px;
    width: 48px;
  }

  .openSeaImg{
    height: 45px;
    width: 45px;

  }

  .nav button {
    margin-left: 0px;
  }

  .nifty-logo span{
    font-size: 45px
  }

  .nav-links {
    display:none;
  }

  .nav {
    justify-content: center
  }

  .section-1 {
    height: 1000px !important
  }
}

@media  screen and (max-width:500px) {
  .msg1 {
    font-size: 25px;
  }

  .nifty-logo span{
    font-size: 30px
  }

  .niftFloat-container > div {
    height: 200px !important;
    width:200px !important;

  }
}
.green{
  color: rgb(52, 155, 52);
}

.blue{
  color: rgb(4, 36, 245);
}
.niftFloat-container{
  display: inherit;
  justify-content: space-around;
  width: 100%;
  margin-top: 200px;
}
.niftFloat-container > div {
  height: 300px;
  width: 300px;
  background: transparent;
  /* animation: rotation1 10s infinite linear; */
}

.nifty-logo span{
  font-family: 'genty sans'
}

.niftFloat-container img{
  height: 100%;
  width: 100%;
}

.nif.one{
  animation: rotation2 20s infinite alternate;
  transform-origin: right -1px;
}

.nif.two{
  /* display: none; */
  animation: rotation1 24s infinite linear;
  transform: rotate(90deg);

  /* transform-origin: right -20px; */
}

.nif.three{
  /* animation: rotation1 20s infinite alternate; */
  animation: rotation1 20s infinite alternate;
  transform-origin: center -1px;
  /* transform: rotate(90deg); */
}

/* .nif.one img{
  animation:  move 10s infinite alternate;
} */
@keyframes rotation1 {
  from {
    transform: rotate(0deg); /**/
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation2 {
  from {
    transform: rotate(-0deg); /**/
  }
  to {
    transform: rotate(-359deg);
  }
}


@keyframes swing {
  0% { transform: rotate(9deg); }
  100% { transform: rotate(-9deg); }
}

@keyframes move {
  0% {
      transform: translateY(25%);
  }

  100% {
      transform: translateY(-25%);
  }
}
