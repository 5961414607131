.section-5{
  background: linear-gradient( #467CE1, #6889C8);
  /* height: 300px; */
  width: 100%;
  display: flex;
}

.section-5-container{
  padding: 40px;
  width: 100%;
  display: inherit;
  flex-direction: column;
  align-items: center
}

@media screen and (max-width: 1500px) {
  .section-5-container h1{
    text-align: center;
    font-size: 90px;
  }
}

/*
.roadmap-container{
  width: 100%;
}

.element-container-1{
  display: flex;
  justify-content: flex-start;
  padding: 60px
}

.element-container-2{
  display: flex;
  justify-content: flex-end;
  padding: 60px
} */

/* .circle-element {
  width:500px;
  height: 500px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgb(11, 36, 83);
} */
