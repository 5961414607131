body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #070F1E;

  color:white;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  height: 100vh; */
  font-family: 'genty sans';
}

@font-face {
  font-family: 'genty sans';
  src: url('./fonts/GentySans-Regular.woff');
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: 'finger paint';
  src: url('./fonts/Finger Paint Regular.woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'abys';
  src: url('./fonts/Abys-Regular.woff');
  font-weight: 100;
  font-style: normal;
 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

