.section-7{
  background-color: #ADC3ED;
  /* height: 300px; */
  width: 100%;

}


.section-7-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center
}

.section-7-container h1 {
  font-size: 100px;
  text-shadow:
    0 1px #0a5677,
    1px 0 #68a8cf,
    1px 2px #0a5677,
    2px 1px #68a8cf,
    2px 3px #0a5677,
    3px 2px #68a8cf,
    3px 4px #0a5677,
    4px 3px #68a8cf,
    4px 5px #0a5677,
    5px 4px #68a8cf,
    5px 6px #0a5677,
    6px 5px #68a8cf
}

@media screen and (max-width: 1500px) {
  .section-7-container h1{
    text-align: center;
    font-size: 90px;
  }
}
@media screen and (max-width: 900px) {
.section-7-container h1{
  font-size: 70px;
}

.right p {
  font-size: 20px !important;
}
}


@media  screen and (max-width:700px) {
  .section-7-container h1{
    font-size: 50px !important;
    text-align: center !important;
  }
  .right p {
    font-size: 17px !important;
  }

}

@media  screen and (max-width:500px) {
  .right p {
    font-size: 15px !important;
  }
}
.card-container{
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
}

.card {
  width: 750px;
  height: 450px;
  background-color: beige;
  margin: 40px 40px;
  border-radius: 10px;
  /* border: 3px solid gray; */
  box-shadow: 5px 5px 10px rgb(30, 72, 128);
  display: flex;
}

.left p {
  font-size: 30px;
  margin: 13px 0px 0px 0px;
  color: rgb(80, 79, 79);
}

.left{
  /* background-color: pink; */
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left img {
  background-color: white;
  width: 90%;
  height: 75%;
  border: 2px solid rgb(175, 175, 175);
}

.right{
 /* background-color: rgb(192, 255, 192); */
 width: 50%;
 height: 100%;
 display: flex;
 flex-direction: column;
 align-items: center
 /* padding: 10px 10px; */
}

.right p {
  font-size: 25px;
  color: rgb(80, 79, 79);
  margin: 20px;
}

.right h3 {
  color: rgb(80, 79, 79);
  margin: 20px 0px 0px 0px;
}
