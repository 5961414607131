.faqs-box {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: #C9E265;
}

.faqs-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: auto;
    margin-bottom: 5em;
    text-shadow: -1px -1px 1px rgba(10, 10, 10, 0.1), 1px 1px 1px rgba(0,0,0,.5);

}

.faqs-header {
    text-align: center;
    /* font-size: 60px; */
}

.faqs-header h1{
    font-size: 100px;
    text-shadow:
    0 1px #145a03,
    1px 0 #45863c,
    1px 2px #145a03,
    2px 1px #45863c,
    2px 3px #145a03,
    3px 2px #45863c,
    3px 4px #145a03,
    4px 3px #45863c,
    4px 5px #145a03,
    5px 4px #45863c,
    5px 6px #145a03,
    6px 5px #45863c
}

@media screen and (max-width: 1500px) {
    .faqs-header h1{
      text-align: center;
      font-size: 90px;
    }
  }

  @media screen and (max-width: 900px) {
    .faqs-header h1{
      font-size: 70px;
    }
    }

    @media  screen and (max-width:700px) {
        .faqs-header h1{
          font-size: 50px !important;
          text-align: center !important;
        }
        .faqs-questions-container{
            font-size: 20px !important
        }

      }

      @media  screen and (max-width:500px) {
        .faqs-header h1{
          font-size: 50px !important;
          text-align: center !important;
        }

        .faqs-questions-container{
            font-size: 15px !important
        }

      }
.faqs-questions-box {
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.faqs-questions-container {
    display: flex;
    cursor: pointer;
    font-size: 30px;
    /* margin-left: 5em; */
    /* width: 70%; */

}


.questions {
    width: 100%
}
