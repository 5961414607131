.section-4 {
  background: linear-gradient(#1A4EAF, #467CE1 );
  /* height: 300px; */
  width: 100%;
  display: flex;
}

.section-4-container{
  padding: 40px;
  width: 100%;
  display: inherit;
  flex-direction: column;
  align-items: center
}

.section-4-container h1{
  font-size: 100px;
  text-shadow:
    0 1px #0a5677,
    1px 0 #68a8cf,
    1px 2px #0a5677,
    2px 1px #68a8cf,
    2px 3px #0a5677,
    3px 2px #68a8cf,
    3px 4px #0a5677,
    4px 3px #68a8cf,
    4px 5px #0a5677,
    5px 4px #68a8cf,
    5px 6px #0a5677,
    6px 5px #68a8cf
}

@media screen and (max-width: 1500px) {
  .section-4-container h1{
    text-align: center;
    font-size: 90px;
  }
}

@media screen and (max-width: 900px) {
  .element-container-1{
    display: flex;
    justify-content:center !important;
    /* padding: 60px */
  }

  .element-container-2{
    display: flex;
    justify-content: center !important;
    /* padding: 60px */
  }
  .section-4-container h1{
    font-size: 70px;
  }
  .circle-element {
    width:400px !important;
    height: 400px !important;
  }

.element-container-1{
  padding: 30px !important
}
}
.roadmap-container{
  width: 100%;
}

.element-container-1{
  display: flex;
  justify-content: flex-start;
  padding: 60px
}

.element-container-2{
  display: flex;
  justify-content: flex-end;
  padding: 60px
}

.circle-element {
  width:500px;
  height: 500px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 10px 10px 5px rgb(11, 36, 83);
}

@media  screen and (max-width:500px) {
  .section-4-container h1{
    font-size: 50px;
    text-align: center !important;
  }

  .circle-element {
    width:300px !important;
    height: 300px !important;
  }

.element-container-1{
  padding: 10px !important
}

}
